import { Heading, Picture, TextLink } from '@troon/ui';
import { Suspense, Show } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { IconArrowRightMd } from '@troon/icons/arrow-right-md';
import { IconTag } from '@troon/icons/tag';
import { clientOnly } from '@solidjs/start';
import type { ParentProps } from 'solid-js';

type Props = {
	withFeaturedDeals?: boolean;
	withLinks?: boolean;
};

const TeeTimes = clientOnly(async () => ({ default: (await import('./tee-times')).AccessPromoTeeTimes }));

export function AccessBenefits(props: Props) {
	return (
		<div class="flex flex-col gap-4 text-lg @container lg:gap-6">
			<Show when={props.withFeaturedDeals !== false}>
				<GridBox class="flex basis-full flex-col gap-12 py-6 @7xl:py-16">
					<div class="px-6 md:px-16">
						<Heading as="h3" size="h4">
							Featured Troon Access Offers
						</Heading>
					</div>
					<Suspense>
						<TeeTimes />
					</Suspense>
					<p class="max-w-screen-md px-6 md:px-16">
						<b>Savings on every round.</b> Save a minimum of 15% off the public rate on every round. Troon Access+
						cardholders enjoy up to 50% off tee times within 78 hours.
					</p>
				</GridBox>
			</Show>

			<div class="flex flex-col gap-4 @7xl:flex-row @7xl:gap-6">
				<GridBox class="h-fit shrink-0 justify-center @7xl:basis-1/2">
					<div class="flex flex-col gap-4 py-6 @7xl:py-16">
						<div class="flex flex-col gap-4 px-6 @7xl:px-16 ">
							<p>
								<b>Access to over 150 world-class courses.</b> From Kapalua, to Gamble Sands and Troon North – your
								Troon Access benefits give you a network of premier courses to play.
							</p>
							<Show when={props.withLinks !== false}>
								<p class="text-base">
									<TextLink href="#explore-courses" class="text-brand-600">
										Explore Courses <IconArrowRightMd />
									</TextLink>
								</p>
							</Show>
						</div>

						<Picture
							src="https://images.ctfassets.net/rdsy7xf5c8dt/1TlSRTAPnGbThoqGNkCTvH/e6e471a72e5a5852c754e7e26f02b3c1/course-graphic.png"
							alt=""
							width={628}
							height={442}
							sizes="(min-width: 1024px) 50vw, 95vw"
							class="w-full"
						/>
					</div>
				</GridBox>

				<div class="flex flex-col gap-4 @7xl:gap-6">
					<GridBox class="p-6 @7xl:p-16">
						<div class="flex items-start gap-8">
							<div class="relative shrink-0">
								<span class="absolute inset-0 z-0 rounded-full bg-gradient-to-t from-brand-700 to-brand-200 motion-safe:animate-ping" />
								<div class="relative z-10 rounded-full bg-gradient-to-t from-neutral-950 to-brand-700 p-3 text-white ">
									<IconTag class="size-8" />
								</div>
							</div>
							<div class="flex flex-col gap-4">
								<p>
									<b>Exclusive perks & partner offers.</b> Enjoy additional benefits like partner offers and 10% savings
									on pro-shop merchandise.
								</p>
								<Show when={props.withLinks !== false}>
									<p class="text-base">
										<TextLink href="/access#benefits" class="text-brand-600">
											View Offers <IconArrowRightMd />
										</TextLink>
									</p>
								</Show>
							</div>
						</div>
					</GridBox>

					<GridBox class="p-6 @7xl:row-span-2 @7xl:p-16">
						<Picture
							src="https://images.ctfassets.net/rdsy7xf5c8dt/2sFoL0JCi7NRHiaXf1U8Ph/81ff38cec96bf699e346bcd691e67017/guest-pass-share-transparent.png"
							width={500}
							height={274}
							sizes="(min-width: 1024px) 50vw, 95vw"
							alt=""
							class="mx-auto"
						/>
						<div class="flex flex-col gap-4">
							<p>
								<b>Guest Passes</b> allow your playing partners to play at the same discounted rate as you for a given
								round. All Troon Access members receive <b>3 Guest Passes</b> per/year with no restrictions on when they
								can be used.
							</p>
							<Show when={props.withLinks !== false}>
								<p class="text-base">
									<TextLink href="/access/guest-passes" class="text-brand-600">
										Learn More<span class="sr-only"> about guest passes</span> <IconArrowRightMd />
									</TextLink>
								</p>
							</Show>
						</div>
					</GridBox>
				</div>
			</div>
		</div>
	);
}

function GridBox(props: ParentProps<{ class?: string }>) {
	return (
		<div class={twJoin('flex flex-col justify-center gap-6 rounded border border-neutral bg-neutral-100', props.class)}>
			{props.children}
		</div>
	);
}
